<template>
  <div>
    <div class="flex justify-between">
      <div >
        <div class="flex items-start gap-2">
          <h5 class="font-semibold text-md">{{ item.name }}</h5>
          <span :class="item.status">
            {{ item.status }}
          </span>
        </div>
        <h6 class="text-sm text-gray-400 font-medium">
          {{ item.created_at | formatDate }}
        </h6>
      </div>
      <div class="flex gap-2">
        <span role="button" v-if="item.status === 'draft'" class="text-amber-600">
          <i-icon class="text-lg" icon="mage:edit" />
        </span>
        <span role="button" class="text-red-600">
          <i-icon class="text-lg" icon="fluent:delete-16-filled" />
        </span>
      </div>
    </div>
    <hr class="my-3" />
    <div>
      <h3 class="font-semibold text-lg">{{ item.subject }}</h3>
      <div class="my-3" v-html="item.body"></div>
      <img :src="item.attachment" alt="" class="h-32 rounded-lg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ID: this.$route.params.id,
      item: {},
    };
  },

  methods: {
    async getDetails() {
      try {
        let res = await this.$http.get(`admin/newsletter/${this.ID}`);
        console.log(res);
        this.item = res.data.newsletter;
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    this.getDetails();
  },
};
</script>


<style>
p {
  font-size: 13px !important;
}

h1 {
  font-size: 24px
}

h2 {
  font-size: 20px;
}
</style>
