<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="40%"
      :show-close="false"
    >
      <template #title>
        <div class="flex justify-end w-full items-center">
          <span role="button" @click="handleClose">
            <i-icon icon="material-symbols:close" color="red" width="20px" />
          </span>
        </div>
      </template>
      <div id="details">
        <div>
          <h5 class="font-bold text-xl">{{ title }}</h5>
          <div>
            <span class="text-sm text-light text-gray-400">{{
              data.id
            }}</span>
          </div>
          <h6 class="text-xs text-light text-gray-400">
            {{ timeStamp2(date) }}
          </h6>
        </div>
        <div class="grid grid-cols-1 gap-1 mt-5">
          <div
            v-for="(value, name) in filterData"
            :key="value"
            class="odd:bg-gray-50 px-3 py-1"
          >
            <span class="text-xs capitalize font-bold"
              >{{ name.split("_").join(" ") }}:</span
            >
            <div
              v-if="typeof value !== 'object'"
              class="font-medium text-sm"
            >
              <slot :name="name" :value="value">
                <span>
                  <span :class="[name === 'status' ? `status ${value}` : '']">{{
                    value ? value : "- - -"
                  }}</span>
                  <button
                    v-if="name === 'account_number'"
                    class="bg-primary px-[4px] py-1 rounded-[4px] text-white ml-2"
                    @click="$emit('copy')"
                  >
                    <i-icon icon="ion:copy" />
                  </button>
                </span>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-end mt-4 gap-2 actions">
        <div
          class="flex gap-2"
          v-if="hasAction && displayData.status === 'pending'"
        >
          <span
            @click="$emit('payoutsFunc', 'completed')"
            role="button"
            class="bg-green-700 text-xs font-bold text-white flex justify-center items-center rounded-md p-2"
          >
            Accept Payout
          </span>
          <span
            @click="$emit('payoutsFunc', 'rejected')"
            role="button"
            class="bg-primary text-xs font-bold text-white flex justify-center items-center rounded-md p-2"
          >
            Reject Payout
          </span>
        </div>
        <span
          @click="downloadAsPDF"
          role="button"
          class="bg-primary text-xs font-bold text-white flex justify-center items-center rounded-md p-2"
        >
          download as pdf
          <i-icon icon="material-symbols:download" width="17px" />
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pick } from "lodash";
import { timeStamp2 } from "@/plugins/filter";
import html2pdf from "html2pdf.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal Title",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    displayData: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: String,
    },
    displayKey: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      timeStamp2,
      busy: false,
      dialogVisible: false,
    };
  },

  methods: {
    handleClose() {
      this.$emit("toggle");
    },

    async downloadAsPDF() {
      const element = document.getElementById("details"); // Get the specific element to download

      // Define options for the PDF generation
      const options = {
        margin: [20, 20, 20, 20], // Top, Right, Bottom, Left margins
        filename: this.title + this.data.id, // File name when downloaded
        image: { type: "jpg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      // Generate PDF from the element's HTML content
      const pdf = await html2pdf().set(options).from(element).save();
      // const pdf = await html2pdf().from(element).set(options).outputPdf();

      // Initiate the download of the generated PDF
      const blob = new Blob([pdf], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = options.filename;
    },
  },

  watch: {
    visible: {
      handler(val) {
        this.dialogVisible = val;
      },
      immediate: true,
    },
  },

  computed: {
    filterData() {
      return pick(this.displayData, this.displayKey);
    },
  },
};
</script>

<style>
.el-dialog__body {
  padding: 20px 20px;
  padding-top: 0 !important;
}

.el-dialog {
  /* margin: 0 !important; */
  border-radius: 8px !important;
  box-shadow: unset !important;
}
</style>
