<template>
  <div>
    <div class="">
      <div class="bg-gray-100 flex gap-2 mb-3 rounded-sm p-2">
        <span
          role="button"
          v-for="(item, idx) in tabs"
          :key="idx"
          @click="activateTab(item, idx)"
          :class="[
            item.title === active
              ? 'bg-primaryColor text-white font-medium'
              : 'bg-white',
          ]"
          class="font-medium capitalize text-[12px] py-[8px] px-4 block rounded-sm"
        >
          {{ item.title.split("-").join(" ") }}
        </span>
      </div>
      <div>
        <component :is="tabs[activeTab].component" />
      </div>
    </div>
  </div>
</template>

<script>
import TagsComponent from "@/components/Settings/TagsComponent.vue";
import CategoriesComponent from "@/components/Settings/CategoriesComponent.vue";
import ShippingComponent from "@/components/Settings/ShippingComponent.vue";
// import VendorRequests from '@/components/Settings/VendorRequests.vue';
import VendorKYC from "@/components/Settings/VendorKYC.vue";
import ManufacturerApplications from "@/components/Settings/ManufacturerApplications.vue";
import RolesAndPermissions from "./RolesAndPermissions.vue";
export default {
  components: {
  },
  data() {
    return {
      items: [],
      visible: false,
      busy: false,
      role: "",
      fields: [],
      tabs: [
        {
          title: "tags",
          component: TagsComponent,
        },
        {
          title: "categories",
          component: CategoriesComponent,
        },
        {
          title: "shipping",
          component: ShippingComponent,
        },
        {
          title: "vendor-requests",
          component: VendorKYC,
        },
        {
          title: "wholesaler/manufacturer-applications",
          component: ManufacturerApplications,
        },
        {
          title: "roles-and-permissions",
          component: RolesAndPermissions,
        },
      ],
      active: "tags",
      activeTab: 0,
    };
  },

  methods: {
    cancel() {
      this.visible = false;
    },

    ok(value) {
      console.log(value);
    },

    activateTab(item, idx) {
      this.active = item.title
      this.activeTab = idx
    }
  },

  beforeMount() {
    //   this.getUsers();
  },

  mounted() {},
};
</script>

<style></style>
