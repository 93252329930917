<template>
  <div class="bg-white p-4">
    <div class="flex flex-justify-between">
      <div v-if="$slots.search">
        <slot name="search" class="mb-3"></slot>
      </div>
      <div v-if="$slots.button">
        <slot name="button" class="mb-3"></slot>
      </div>
    </div>
    <b-table
      id="globalTable"
      :items="items"
      :fields="fields"
      stacked="md"
      :filter="filter"
      :scrollable="scrollable"
      hover
      responsive
      show-empty
      sort-icon-left
      :busy="busy"
      class="custom-table table-responsive mt-2"
      :class="classCustom"
      :table-class="tableClass"
      @row-clicked="rowClickAction($event)"
      :no-local-sorting="false"
      @row-hovered="sortEvent"
      @row-unhovered="onRowUnhovered"
    >
      <template #cell(fullname)="data">
        <span class="text-capitalize"
          >{{ data.item.firstName }} {{ data.item.lastName }}</span
        >
      </template>
      <template #head()="{ label, field: { key, sortable } }">
        {{ label }}
        <template v-if="sortable">
          <!-- Custom icons -->
          <template v-if="useCustomIcons">
            <!-- <img v-if="sortBy !== key" src="icons/table-sort.svg" alt="" > -->
            <!-- <b-icon
                v-if="sortBy !== key"
                icon="arrow-down-up"
                class="bold-icon"
              ></b-icon> -->
            <b-icon
              v-if="sortBy !== key"
              icon="arrows-collapse"
              class="bold-icon"
            ></b-icon>
            <b-icon
              v-else-if="sortDesc"
              icon="arrow-down"
              class="bold-icon"
            ></b-icon>
            <b-icon v-else icon="arrow-up" class="bold-icon"></b-icon>
          </template>
          <!-- Normal icons -->
          <template v-else>
            <b-img
              v-if="sortBy !== key"
              :src="sortIconNeutral"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
            <b-img
              v-else-if="sortDesc"
              :src="sortIconAsc"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
            <b-img
              v-else
              :src="sortIconDesc"
              style="width: 0.65rem; height: 1rem"
            ></b-img>
          </template>
        </template>
      </template>
      <template #empty>
        <div class="p-4">
          <div
            class="d-flex align-items-center flex-column justify-content-center"
          >
            <h1 class="no-data">NO DATA RECORDED</h1>
            <!-- <img src="@/assets/img/empty.svg" alt="empty-list" class="empty" /> -->
          </div>
        </div>
      </template>

      <template #table-busy>
        <b-skeleton-table
          :rows="4"
          :columns="4"
          :table-props="{ bordered: false, striped: false }"
        ></b-skeleton-table>
      </template>
      <template #cell(edit)="item">
        <slot name="edit" :data="item"></slot>
      </template>
      <template #cell(status)="data">
        <slot name="status" :data="data">
          <span class="status block w-fit" :class="data.item.status">{{
            data.item.status
          }}</span>
        </slot>
      </template>
      <template #cell(is_registered)="data">
        <slot name="status" :data="data">
          <span
            class="status"
            :class="
              Boolean(data.item.status.is_registered) ? 'approved' : 'canceled'
            "
            >{{
              Boolean(data.item.is_registered) ? "registered" : "not registered"
            }}</span
          >
        </slot>
      </template>
      <template #cell(availability)="data">
        <slot name="availability" :data="data">
          <span class="availability" :class="data.item.availability">{{
            data.item.availability
          }}</span>
        </slot>
      </template>
      <template #cell(encounter_datetime)="data">
        <slot name="encounter_datetime" :data="data">
          <div>
            {{ formatDate(data.item.encounter_datetime) }}
          </div>
        </slot>
      </template>
      <template #cell(created_at)="data">
        <slot name="created_at" :data="data">
          <div>
            {{ formatDate(data.item.created_at) }}
          </div>
        </slot>
      </template>
      <template #cell(updated_at)="data">
        <slot name="updated_at" :data="data">
          <div>
            {{ formatDate(data.item.updated_at) }}
          </div>
        </slot>
      </template>
      <template #cell(ordered_datetime)="data">
        <slot name="ordered_datetime" :data="data">
          <div>
            {{ formatDate(data.item.ordered_datetime) }}
          </div>
        </slot>
      </template>
      <template #cell(date)="data">
        <slot name="date" :data="data">
          <div>
            {{ formatDate(data.item.date) }}
          </div>
        </slot>
      </template>
      <template #cell(time)="data">
        <slot name="time" :data="data">
          <div>
            {{ formatDate(data.item.time) }}
          </div>
        </slot>
      </template>
      <template #cell(createdAt)="data">
        <slot name="createdAt" :data="data">
          <div>
            {{ formatDate(data.item.createdAt) }}
          </div>
        </slot>
      </template>

      <template #cell(button)="data">
        <div class="flex justify-end">
          <button
            class="warehauze-btn warehauze-primary w-fit capitalize"
            @click="$emit(btnValue, data.item)"
          >
            {{ btnValue.split("_").join(" ") }}
          </button>
        </div>
      </template>

      <template #cell(triple_actions)="data">
        <slot name="triple_actions" :data="data">
          {{ data.value }}
        </slot>
      </template>
      <template #cell(dots)="row">
        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <b-icon icon="three-dots-vertical" class="dot"></b-icon>
          </template>
          <template v-if="dropdownItem.length > 0">
            <b-dropdown-item
              v-for="(dropdown, index) in dropdownItem"
              :key="index"
              class="text-capitalize"
              @click="$emit(dropdown, row.item)"
              >{{ dropdown.split("_").join(" ") }}</b-dropdown-item
            >
          </template>
        </b-dropdown>
      </template>
      <template #cell(totalOrderAmount)="data">
        <span>{{
          (data.item.pivot.quantity * data.item.price) | formatCurrency
        }}</span>
      </template>
      <template #cell(uom)="data">
        <span name="uom"> {{ data.item.uom }}</span>
      </template>
      <template #cell(reference_range)="item">
        <slot name="reference_range" :data="item"></slot>
      </template>
      <template #cell(color)="data">
        <slot name="color" :data="data">
          <div
            class="rounded-circle"
            style="height: 25px; width: 25px"
            :style="{ backgroundColor: data.value }"
          ></div>
        </slot>
      </template>
      <template #cell(action)="data">
        <slot name="action" :data="data">{{ data.value }}</slot>
      </template>

      <template #cell(panel)="item">
        <slot name="panel" :data="item"></slot>
      </template>
      <template #cell(specimen_type)="item">
        <slot name="specimen_type" :data="item"></slot>
      </template>
      <template #cell(tx_amount)="data">
        <span>{{
          Number(data.item.amount).toLocaleString() + " " + data.item.currency
        }}</span>
      </template>

      <template #cell(lab_unit)="item">
        <slot name="lab_unit" :data="item"></slot>
      </template>

      <template #cell(obv)="item">
        <slot name="obv" :data="item"></slot>
      </template>

      <template #cell(app_image)="data">
        <img
          class="w-[30px] h-[30px] p-[2px] border border-primaryColor object-cover object-center rounded-full"
          :src="`${config.imgUrl}${data.item.app_icon}`"
          alt=""
        />
      </template>

      <template #cell(table_img)="data">
        <img
          class="w-[30px] h-[30px] p-[2px] border border-primaryColor object-cover object-center rounded-full"
          :src="`${config.imgUrl}${data.item.icon_image}`"
          alt=""
        />
      </template>

      <template #cell(editableEmail)="data">
        <input
          v-if="editCellId == data.item.id"
          @keyup.enter="$emit('complete', data.item)"
          type="text"
          v-model="data.item.email"
        />
        <span v-else> {{ data.item.email }}</span>
      </template>

      <template #cell(actions)="row">
        <div class="flex gap-2 items-center justify-end">
          <button
            v-if="!disableEditAction"
            @click="$emit('edit', row.item)"
            class="text-amber-700 w-fit"
          >
            <i-icon icon="iconamoon:edit" width="16px" />
          </button>
          <el-popconfirm
            confirm-button-text="Delete"
            cancel-button-text="No, Thanks"
            icon="el-icon-info"
            icon-color="red"
            title="Continue with delete?"
            @confirm="$emit('delete', row.item)"
          >
            <!-- <span  class="text-red-600">
                  <i-icon :icon="deleteLoading === item.id ? 'line-md:loading-alt-loop' : 'fluent:delete-16-filled'" />
                </span> -->
            <button
              slot="reference"
              v-if="!disableDeleteAction"
              class="text-red-600 w-fit"
            >
              <i-icon icon="fluent:delete-12-regular" width="16px" />
            </button>
          </el-popconfirm>

          <button
            v-if="!disableViewAction"
            @click="$emit('view', row.item)"
            class="text-green-600 w-fit"
          >
            <i-icon icon="grommet-icons:view" width="16px" />
          </button>
        </div>
      </template>
    </b-table>
    <div class="flex w-full items-center gap-2" v-if="paginate">
      <vPagination  :meta="meta" @next="$emit('next', $event)" />
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/plugins/filters";
export default {
  props: {
    editCell: {
      type: String,
      default: null,
    },
    isClientPagination: {
      type: Boolean,
      default: false,
    },
    showTableSearch: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    meta: {
      type: Object,
      default: () => {},
    },

    classCustom: {
      type: String,
      default: "custom-class",
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    /**
     * @description
     * The number of pages coming from the server(API)
     */
    pages: {
      type: Number,
      default: 1,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    showBaseCount: {
      type: Boolean,
      default: true,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    /**
     * @description
     * If true, pagination will be displayed
     */
    paginate: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: String,
      required: false,
      default: "",
    },
    searchFilters: {
      type: String,
      required: false,
      default: "",
    },
    // this is for the three dots dropdown
    dropdownItem: {
      type: Array,
      default: () => [],
    },
    tableClass: {
      type: String,
      default: "custom-table pointer",
    },
    disableEditAction: {
      type: Boolean,
      default: false,
    },
    disableDeleteAction: {
      type: Boolean,
      default: false,
    },
    disableViewAction: {
      type: Boolean,
      default: false,
    },
    filterbank: {
      type: Array,
      default: () => [],
    },
    btnValue: {
      type: String,
    },
  },
  data: () => {
    return {
      formatDate,
      page: "",
      editCellId: null,
    };
  },

  methods: {
    rowClickAction(item) {
      this.$emit("row-clicked", item);
    },
    onRowUnhovered(e) {
      this.$emit("unhoverData", e);
    },
    sortEvent(e) {
      this.$emit("sortData", e);
    },
  },

  mounted() {
    // this.totalRows = this.perPage * this.pages;
  },

  watch: {
    editCell: {
      handler(val) {
        this.editCellId = val;
      },
      immediate: true,
    },
  },
};
</script>

<style>
.no-data {
  font-size: 16px;
}

.empty {
  width: 150px;
  height: 150px;
}

.page-link.active,
.active > .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>
